import { CheckoutAccordion, CheckoutAccordionItem, DefaultStrategy } from './CheckoutAccordion'
import CheckoutAccordionInpostBody from './CheckoutAccordionInpostBody'
import { InpostModal } from '../InpostModal/InpostModal'

class CheckoutDeliveryAccordion extends CheckoutAccordion {
	getErrorMessage () {
		return this.translations.parcel
	}
}

class InpostStrategy extends DefaultStrategy {
	body = CheckoutAccordionInpostBody
	point
	error

	async openModal () {
		this.point = await InpostModal.openAndReturnPoint(this.point?.name)
		this.validate()
	}

	get translations () {
		return window.app.translator.get('errors')
	}

	getValue () {
		const value = super.getValue()
		value.point = this.point
		return value
	}

	validate () {
		const valid = !!this.point
		if (!valid) this.error = this.translations.parcel
		else this.error = false
		return valid
	}

	setInitialActive (data) {
		super.setInitialActive()
		this.point = data.point
	}
}

class CheckoutDeliveryAccordionItem extends CheckoutAccordionItem {
	body
	header

	name
	alias
	cost

	content

	constructor ({ alias, cost, name, data }) {
		super()

		const Strategy = {
			inpost: InpostStrategy
		}[alias] || DefaultStrategy

		this.strategy = new Strategy(this)
		this.body = this.strategy.body
		this.header = this.strategy.header

		this.name = name
		this.alias = alias
		this.cost = cost
		this.content = data.info
	}
}

export { CheckoutDeliveryAccordion, CheckoutDeliveryAccordionItem }
