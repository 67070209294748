<template>
	<div class="checkout-accordion-body" :class="{active: item.strategy.point, 'invalid': item.strategy.error}">
		<div class="inpost-wrapper" v-if="item.strategy.point">
			<base-font variant="small-header" marginReset weight="bold">{{langs.accordion_title}} {{item.strategy.point.name}}</base-font>
			<div class="inpost-data">
				<base-font>{{item.strategy.point.address.line1}}</base-font>
				<base-font>{{item.strategy.point.address.line2}}</base-font>
			</div>
		</div>
		<div class="btn-wrapper">
			<base-button
				class="checkout-accordion-body-button"
				:class="{'invalid': item.strategy.error}"
				@click="item.strategy.openModal()"
				type="secondary"
			>
				<template v-if="item.strategy.point">
					{{langs.edit}}
				</template>
				<template v-else>
					{{langs.show}}
				</template>
			</base-button>
			<div v-if="item.strategy.error" class="error-wrapper">
				<p class="field-error">
					{{item.strategy.error}}
				</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		item: Object
	},
	computed: {
		langs () {
			return this.$app.translator.get('forms.inpost')
		}
	}
}
</script>
<style lang="scss" scoped>
.checkout-accordion-body {
	display: flex;
	flex-direction: column;
    justify-content: center;
    align-items: center;
	padding: 2rem;
	background-color: rgba($gray-500, .4);
	border: 1px solid $gray-700;
	z-index: 1;
	position: relative;

	@include media-breakpoint-up(xxl) {
		flex-direction: row;
	}
	&.invalid {
		border: 1px solid $danger-500;
	}
	&-button {
		width: auto;
	}
	&.active {
		justify-content: space-between;
	}
	& :deep(.checkout-accordion-body-button) {
		margin-top: 0;
		margin-bottom: 0;
	}
	.inpost-wrapper {
		margin-right: 5rem;
	}
	.inpost-data {
		display: flex;
		flex-direction: column;
	}
	:deep(.btn.invalid ){
		border: 1px solid $danger-500;
		background: $white;
		color: $danger-500;
		.svg-icon path {
			stroke: red;
		}
	}
	.error-wrapper {
		min-height: 20px;
		text-align: right;
		@include transition;
		margin-top: 10px;
		&:deep(.field-error) {
			flex-basis: 100%;
			color: $input-error-color;
			font-size: 12px;
			line-height: 16px;
			overflow: hidden;
			@include transition;
		}
		@include media-breakpoint-up(lg) {
			&:deep(.field-error) {
				font-size: 12px;
			}
		}
	}
}
</style>
