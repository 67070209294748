import { ReactiveClass } from '@f/core/ReactiveClass'

class Accordion extends ReactiveClass {
	items = []
	active

	setActive (item) {
		if (item === this.active) return false
		this.active = item
		this._emit('active:changed')
	}

	addItem (item) {
		item.accordion = this
		this.items.push(item)
	}
}

class AccordionItem {
	header
	body
	accordion

	get isActive () {
		return this.accordion.active === this
	}

	open () {
		this.accordion.setActive(this)
	}
}

export { Accordion, AccordionItem }
