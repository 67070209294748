<template>
	<div class="order-bump">
		<order-bump-product-list-item v-for="product in products" :key="product.id" :product="product" :horizontalSlider="false" slim />
	</div>
</template>

<script>
import OrderBumpProductListItem from '~/shop/front/components/OrderBumpProductListItem'

export default {
	components: {
		OrderBumpProductListItem
	},
	props: {
		products: Array
	}
}
</script>

<style scoped lang="scss">
.order-bump {
	display: flex;
	flex-direction: column;
	gap: 3rem;
}
</style>
