import { Form } from '@f/core/forms/Form'
import { InputText, InputRadio, RadioGroup, InputCheckbox } from '@f/core/forms/inputs'
import { asyncForEach } from 'utils/async'
import { nip, required } from 'utils/inputs/validation'

class InvoiceCompanyForm extends Form {
	activeInputs = {
		invoiceCompany: ['companyName', 'companyNIP', 'invoiceAddress', 'invoicePostalCode', 'invoiceCity'],
		privatePerson: ['invoiceName', 'invoiceLastname', 'invoiceAddress', 'invoicePostalCode', 'invoiceCity']
	}

	constructor (app) {
		super()
		this.app = app
	}

	get isActive () {
		if (this.inputs.otherDataToInvoice.value) return true
		return false
	}

	getValues () {
		if (!this.isActive) return false
		return super.getValues()
	}

	async validate () {
		if (!this.isActive) return true
		const activeInputs = this.activeInputs[this.inputs.invoiceType.value]
		const inputs = Object.entries(this.inputs).filter(([key]) => activeInputs.includes(key)).map(([key, input]) => input)

		let isFormValid = true
		await asyncForEach(inputs, async input => {
			const isInputValid = await input.touchAndValidate()
			if (!isInputValid) isFormValid = false
		})

		return isFormValid
	}

	async create (draftOrder) {
		this.addInput(InputCheckbox.create({
			translations: () => this.app.translator.get('forms.checkout.otherDataToInvoice'),
			attribute: 'otherDataToInvoice',
			name: 'otherDataToInvoice',
			value: draftOrder?.invoice?.otherDataToInvoice
		}))
		this.addInput(InputText.create({
			translations: () => this.app.translator.get('forms.checkout.companyName'),
			attribute: 'companyName',
			name: 'companyName',
			value: draftOrder?.invoice?.companyName,
			validation: [required()]
		}))
		this.addInput(InputText.create({
			translations: () => this.app.translator.get('forms.checkout.companyNIP'),
			attribute: 'companyNIP',
			name: 'companyNIP',
			mask: '##########',
			value: draftOrder?.invoice?.companyNIP,
			inputmode: 'numeric',
			validation: [required(), nip()]
		}))
		this.addInput(InputText.create({
			translations: () => this.app.translator.get('forms.checkout.companyAddress'),
			attribute: 'address',
			name: 'invoiceAddress',
			value: draftOrder?.invoice?.address,
			validation: [required()]
		}))
		this.addInput(InputText.create({
			translations: () => this.app.translator.get('forms.checkout.companyPostalCode'),
			attribute: 'postalCode',
			name: 'invoicePostalCode',
			mask: '##-###',
			value: draftOrder?.invoice?.postalCode,
			inputmode: 'numeric',
			validation: [required()]
		}))
		this.addInput(InputText.create({
			translations: () => this.app.translator.get('forms.checkout.companyCity'),
			attribute: 'city',
			name: 'invoiceCity',
			value: draftOrder?.invoice?.city,
			validation: [required()]
		}))

		this.addInput(InputText.create({
			translations: () => window.app.translator.get('forms.checkout.personName'),
			attribute: 'name',
			name: 'invoiceName',
			value: draftOrder?.invoice?.name,
			validation: [required()]
		}))
		this.addInput(InputText.create({
			translations: () => window.app.translator.get('forms.checkout.personLastname'),
			attribute: 'lastname',
			name: 'invoiceLastname',
			value: draftOrder?.invoice?.lastname,
			validation: [required()]
		}))

		const radioGroup = new RadioGroup('invoiceType')

		radioGroup.addInput(InputRadio.create({
			translations: () => this.app.translator.get('forms.checkout.invoiceCompany'),
			attribute: 'invoiceCompany',
			name: 'invoiceCompany',
			radioName: 'invoiceType',
			value: 'invoiceCompany',
			checked: true,
			parent: radioGroup
		}))
		radioGroup.addInput(InputRadio.create({
			translations: () => this.app.translator.get('forms.checkout.privatePerson'),
			attribute: 'privatePerson',
			name: 'privatePerson',
			radioName: 'invoiceType',
			value: 'privatePerson',
			parent: radioGroup
		}))
		this.addInput(radioGroup)
	}
}

export { InvoiceCompanyForm }
