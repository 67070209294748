import { Page } from '~/cms/front/core/pages/Page'
// import { InputRadio, RadioGroup } from '@f/core/forms/inputs'
import component from './Checkout.vue'

import { DeliveryForm } from './forms/DeliveryForm'
import { ClientForm } from './forms/ClientForm'
import { PaymentsForm } from './forms/PaymentsForm'
import { InvoiceCompanyForm } from './forms/InvoiceCompanyForm'
import { asyncMap } from 'utils/async'
import { OthersForm } from './forms/OthersForm'
import { getPath } from 'utils/getPath'
import { MetaPixelService } from '~/website/front/core/services/MetaPixelService'

class CheckoutPage extends Page {
	static alias = 'checkout'
	component = component
	showCheckoutProgressBar = true
	ready = false
	draftOrder
	additionalCost = false

	get toPay () {
		return this.additionalCost + this.draftOrder.cart.amount
	}

	processing = false

	forms = {
		delivery: false,
		client: false,
		others: false,
		invoice: false,
		paymentMethod: false
	}

	async init () {
		await super.init()

		if (process.client) {
			setTimeout(() => {
				const facebookPixelData = {
					page_title: document.title,
					event_url: location.href,
					total_price: (this.app.cart.amount || 0) / 100,
					value: (this.app.cart.amount || 0) / 100,
					currency: 'PLN',
					content_type: 'product',
					user_role: this.app.authorization.isAuthorizated ? 'client' : 'guest',
					content_ids: this.app.cart.items.map(item => item.product.id),
					contents: this.app.cart.items.map(item => ({
						id: item.product.id,
						quantity: item.quantity,
						name: item.product.name
					})),
					category_name: this.app.cart.items.map(item => item.product.category ? item.product.category.name : 'brak'),
					content_name: this.app.cart.items.map(item => item.product.name)
				}

				MetaPixelService.emitEvent('InitiateCheckout', facebookPixelData)
			}, 500)
		}
	}

	async createForms () {
		this.forms.delivery = new DeliveryForm(this.app)
		this.forms.client = new ClientForm(this.app)
		this.forms.others = new OthersForm(this.app)
		this.forms.invoice = new InvoiceCompanyForm(this.app)
		this.forms.paymentMethod = new PaymentsForm(this.app)

		this.forms.delivery.on('value:changed', this.updatePaymentsForm.bind(this))
		this.forms.delivery.on('value:changed', this.updateAdditionalCost.bind(this))

		await this.forms.delivery.create(this.draftOrder)
		await this.forms.client.create(this.draftOrder)
		await this.forms.others.create(this.draftOrder)
		await this.forms.invoice.create(this.draftOrder)
		await this.forms.paymentMethod.create(this.draftOrder)
		this.forms.paymentMethod.on('value:changed', this.updateAdditionalCost.bind(this))

		this.updatePaymentsForm()
		this.updateAdditionalCost()
	}

	async fetchDraftOrder () {
		const DraftOrder = this.app.getEntity('draft-order')
		this.draftOrder = await DraftOrder.findById(this.params.id)

		this.app.cart.setCartData(this.draftOrder.cart)
	}

	async afterRedirect () {
		if (!process.client) {
			return false
		}
		await Promise.all([
			this.app.translator.prefetch('checkout'),
			this.app.translator.prefetch('cart'),
			this.app.translator.prefetch('errors'),
			this.app.translator.prefetch('forms.checkout'),
			this.app.translator.prefetch('forms.inpost'),
			this.fetchDraftOrder(),
			this.fetchOrderBumpProducts()
		])
		await this.createForms()
		this.ready = true
		this.app._emit('checkout', this.draftOrder)
	}

	async validateForms () {
		const forms = Object.values(this.forms)
		const result = await asyncMap(forms, async form => await form.validate())
		if (result.includes(false)) return false
		return true
	}

	scrollToInvalid () {
		const firstInvalid = document.querySelector('.invalid')
		const distanceFromTop = firstInvalid.getBoundingClientRect().top + window.scrollY - 50
		window.scrollTo({
			top: distanceFromTop,
			left: 0,
			behavior: 'smooth'
		})
	}

	updatePaymentsForm () {
		const paymentsData = this.forms.delivery.getPayments()
		this.forms.paymentMethod.update(paymentsData)
	}

	updateAdditionalCost () {
		const deliveryCost = this.forms.delivery.cost ?? 0
		const paymentCost = this.forms.paymentMethod.cost ?? 0
		const additionalCost = deliveryCost + paymentCost
		if (additionalCost) this.additionalCost = additionalCost
		else this.additionalCost = false
	}

	goToErrorPage () {
		const errorPath = getPath({
			app: this.app,
			to: 'return',
			query: {
				error: true
			}
		})
		this.app.vue.$router.push(errorPath)
	}

	async submit () {
		this.processing = true
		const valid = await this.validateForms()

		if (!valid) {
			this.processing = false
			this.scrollToInvalid()
			return false
		}

		const data = {}

		await asyncMap(Object.entries(this.forms), async ([name, form]) => {
			data[name] = await form.getValues()
		})

		const { error } = await this.draftOrder.update(data)

		if (error) return this.goToErrorPage()

		const summaryPath = getPath({
			app: this.app,
			to: 'summary',
			params: {
				id: this.draftOrder.id
			}
		})
		this.app.vue.$router.push(summaryPath)

		this.processing = false
	}

	async fetchOrderBumpProducts () {
		const Product = this.app.getEntity('product')
		const promises = Object.values(this.app.settings.shop.orderBump.products).map(product => Product.fetchOne(product.alias))

		const result = await Promise.all([
			...promises
		])

		await this.app.vue.$nextTick()
		this.orderBumpProducts = result.filter(orderBumpProduct => {
			const isInUserCart = this.draftOrder.cart.items.find(item => item.product.id === orderBumpProduct.id)
			if (!isInUserCart) return orderBumpProduct
		})
	}

	async addOrderBumpProduct (product) {
		await product.addToCart()
		await this.fetchDraftOrder()
	}
}

export { CheckoutPage }
