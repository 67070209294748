<template>
	<div :class="{'active': active, disable: item.disable}" class="checkout-accordion-header" v-on="$listeners">
		<div class="inner-wrapper">
			<div class="checkbox-main">
				<div class="checkbox-box">
					<div class="checkbox-checkmark"></div>
				</div>
			</div>
			<div style="width: max-content">{{item.name}}</div>
		</div>
		<div class="checkout-accordion-header-price-icon">
			<div class="checkout-accordion-header-price-wrapper">
				<base-price v-if="item.cost" class="checkout-accordion-header-item checkout-accordion-header-price" :price="item.cost" />
			</div>
			<div class="checkout-accordion-header-icon-wrapper"
				:class="{ 'checkout-accordion-header-icon-payu' : isItemPayu }">
				<base-svg class="checkout-accordion-header-item checkout-accordion-header-icon" :name="item.alias"/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		item: Object,
		active: Boolean
	},
	computed: {
		isItemPayu () {
			return this.item.alias === 'payu'
		}
	}
}
</script>
<style lang="scss" scoped>
.inner-wrapper {
	display: flex;
}
.checkbox {
	&-box {
		position: relative;
		width: 18px;
		height: 18px;
		margin-right: 2rem;
	}
	&-checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 18px;
		width: 18px;
		background-color: $white;
		border: 1px solid $gold-600;
		cursor: pointer;
		&:after {
			content: "";
			position: absolute;
			display: none;
			left: 5px;
			top: 0px;
			width: 7px;
			height: 12px;
			border: solid $primary;
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
		}
	}
}
.checkout-accordion-header.active .checkbox-checkmark {
	background: $gray-200;

	&:after {
		display: block;
	}
}
.checkout-accordion-header.disable {
	color: $gray-600;
	cursor: initial;

	.checkbox-checkmark {
	cursor: initial;
		border-color: $gray-600;
	}
}
.checkout-accordion-header {
	width: 100%;
	padding: 1.5rem 1.5rem 1.5rem 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	align-content: stretch;
	row-gap: 5px;

	@include media-breakpoint-up(lg) {
		padding: 1.5rem 1.5rem 1.5rem 0;
	}

	& &-price-icon {
		display: flex;
		align-items: center;
		column-gap: 10px;
	}
	& &-price-wrapper {
		width: max-content;
	}
	& &-icon-wrapper {
		width: 30px;
	}

	&-content {
		padding: 2rem;
		background-color: rgba(#3A3738, .2);
		border: 1px solid $gray-800;
	}

}
</style>
