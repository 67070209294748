<template>
	<div class="checkout-accordion-body" v-html="item.content"></div>
</template>

<script>
export default {
	props: {
		item: Object
	}
}
</script>

<style lang="scss" scoped>
.checkout-accordion-body {
	padding: 2rem;
	background-color: rgba($gray-500, .4);
	border: 1px solid $gray-700;
}
</style>
