import { Accordion, AccordionItem } from '@f/components/BaseAccordion/Accordion'
import CheckoutAccordionBody from './CheckoutAccordionBody'
import CheckoutAccordionHeader from './CheckoutAccordionHeader'

class DefaultStrategy {
	body = CheckoutAccordionBody
	header = CheckoutAccordionHeader

	constructor (item) {
		this.item = item
	}

	getValue () {
		return {
			alias: this.item.alias
		}
	}

	getCost () {
		return this.item.cost
	}

	validate () {
		return true
	}

	setInitialActive () {
		this.item.open()
	}
}

class CheckoutAccordion extends Accordion {
	error
	getValue () {
		if (!this.active) return false
		return this.active.getValue()
	}

	get translations () {
		return window.app.translator.get('errors')
	}

	get cost () {
		return this.active?.getCost()
	}

	setActive (item) {
		if (item && item.disable) return false
		super.setActive(...arguments)
		this.error = false
	}

	validate () {
		if (!this.active) {
			this.error = this.getErrorMessage()
			return false
		}
		this.error = false
		return this.active.validate()
	}
}

class CheckoutAccordionItem extends AccordionItem {
	disable = false

	body
	header

	name
	alias
	cost

	getValue () {
		return this.strategy.getValue()
	}

	getCost () {
		return this.strategy.getCost()
	}

	validate () {
		return this.strategy.validate()
	}

	setInitialActive () {
		this.strategy.setInitialActive(...arguments)
	}
}

export { CheckoutAccordionItem, CheckoutAccordion, DefaultStrategy }
