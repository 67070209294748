<template>
	<div class="accordion">
		<accordion-item v-for="(item, key) in accordion.items" :key="key" :item="item" :accordion="accordion"/>
	</div>
</template>

<script>
import AccordionItem from './AccordionItem.vue'

export default {
	components: { AccordionItem },
	props: {
		accordion: Object
	}
}
</script>

<style lang="scss" scoped>
.accordion {
	list-style: none;
	margin: 0;
	padding: 0;

	&-item:last-child {
		margin-top: -1px;
	}
}
</style>
