<template>
	<div class="accordion-item">
		<div
			class="accordion-trigger"
			:class="{'active': item.isActive, 'invalid': error}"
			@click="item.open()"
		>
			<compontent :is="item.header" :active="item.isActive" :item="item"/>
		</div>

		<transition
			name="accordion"
			@enter="start"
			@after-enter="end"
			@before-leave="start"
			@after-leave="end"
		>
			<div class="accordion-content-wrapper" v-show="item.isActive">
				<compontent class="accordion-content" :is="item.body"  :item="item"/>
			</div>
		</transition>
	</div>
</template>
<script>
export default {
	props: {
		item: Object,
		accordion: Object
	},
	computed: {
		error () {
			return !!this.accordion.error
		}
	},
	methods: {
		start (el) {
			el.style.height = el.scrollHeight + 'px'
		},
		end (el) {
			el.style.height = ''
		}
	}
}
</script>

<style lang="scss" scoped>
.accordion-item {
	&:first-child {
		.accordion-trigger {
			border: 1px solid $gold;
		}
	}
	&:last-child {
		.accordion-trigger {
			border: 1px solid $gold;
		}
	}
	.accordion-trigger.invalid {
		border: 1px solid $danger-500;
	}
	.accordion-trigger {
		position: relative;
		display: flex;
		justify-content: space-between;
		font-weight: bold;
		cursor: pointer;
		padding-left: 2rem;
		border: 1px solid $gold;
		margin-top: -1px;
	}
}
.accordion-content-wrapper {
	margin-top: -1px;
	.accordion-content {
		@include media-breakpoint-up(xl) {
			padding-left: 4rem;
		}
	}
}
.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>
