import { Form } from '@f/core/forms/Form'
import { InputCheckbox } from '@f/core/forms/inputs'
import { required } from 'utils/inputs/validation'

class OthersForm extends Form {
	constructor (app) {
		super(...arguments)
		this.app = app
	}

	async create (draftOrder = {}) {
		this.addInput(InputCheckbox.create({
			translations: () => this.app.translator.get('forms.checkout.saveData'),
			attribute: 'saveData',
			value: draftOrder.others?.saveData,
			name: 'saveData'
		}))

		this.addInput(InputCheckbox.create({
			translations: () => this.app.translator.get('forms.checkout.regulations'),
			attribute: 'regulations',
			name: 'regulations',
			value: draftOrder.others?.regulations,
			validation: [required()]
		}))
		this.addInput(InputCheckbox.create({
			translations: () => this.app.translator.get('forms.checkout.policy'),
			attribute: 'policy',
			name: 'policy',
			value: draftOrder.others?.policy,
			validation: [required()]
		}))
		this.addInput(InputCheckbox.create({
			translations: () => this.app.translator.get('forms.checkout.marketing'),
			attribute: 'marketing',
			value: draftOrder.others?.marketing,
			name: 'marketing'
		}))
	}
}

export { OthersForm }
