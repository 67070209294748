import { Emitter } from '@f/core/Emitter'
import { CheckoutPaymentsAccordion, CheckoutPaymentsAccordionItem } from '~/shop/front/components/CheckoutAccordion/CheckoutPaymentsAccordion'

class PaymentsForm extends Emitter {
	accordion

	constructor (app) {
		super()
		this.app = app
	}

	async create (draftOrder) {
		const paymentMethods = await draftOrder.getPaymentMethods()

		this.accordion = new CheckoutPaymentsAccordion()
		paymentMethods.map(paymentMethod => {
			const item = new CheckoutPaymentsAccordionItem(paymentMethod)
			this.accordion.addItem(item)
			if (draftOrder?.paymentMethod?.alias === paymentMethod.alias) {
				item.disable = false
				this.accordion.setActive(item)
			}
		})

		this.accordion.on('active:changed', () => this._emit('value:changed', this.accordion.getValue()))
		this.accordion.initReactive()
	}

	validate () {
		return this.accordion.validate()
	}

	getValues () {
		return this.accordion.getValue()
	}

	get cost () {
		return this.accordion.cost
	}

	update (data) {
		const { alias } = this.getValues() || {}
		if (!data[alias]) this.accordion.setActive(null)
		this.accordion.items.map(item => item.update(data[item.alias]))
	}
}

export { PaymentsForm }
