import { CheckoutAccordion, CheckoutAccordionItem, DefaultStrategy } from './CheckoutAccordion.js'

class CheckoutPaymentsAccordion extends CheckoutAccordion {
	getErrorMessage () {
		return this.translations.payments
	}
}

class CheckoutPaymentsAccordionItem extends CheckoutAccordionItem {
	disable = true

	body
	header

	name
	alias

	cost
	content

	constructor ({ alias, name }) {
		super()

		const Strategy = DefaultStrategy

		this.strategy = new Strategy(this)
		this.body = this.strategy.body
		this.header = this.strategy.header

		this.name = name
		this.alias = alias
	}

	update (data) {
		if (!data) {
			this.disable = true
			this.cost = false
			return false
		}
		this.cost = data.cost
		this.disable = false
		this.content = data.info
	}
}

export { CheckoutPaymentsAccordion, CheckoutPaymentsAccordionItem }
