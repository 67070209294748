<template>
	<section class="section">
		<base-container v-if="page.ready">
				<base-row>
					<base-col col="12" md="10" lg="7" offsetMd="1" offsetLg="0">
						<form class="form">
							<divider-heading class="heading">{{langs.titles.delivery}}</divider-heading>
							<div class="form-wrapper">
								<base-accordion class :accordion="page.forms.delivery.accordion"/>
								<base-error :error="page.forms.delivery.accordion.error"/>
							</div>
						</form>
						<form class="form">
							<div>
								<divider-heading class="heading">{{langs.titles.client_data}}</divider-heading>
								<div class="form-wrapper">
									<div class="form-row">
										<input-template :input="page.forms.client.inputs.firstName" />
										<input-template :input="page.forms.client.inputs.lastName" />
									</div>
									<input-template wider :input="page.forms.client.inputs.streetAndBuildingNumber" />
									<div class="form-row">
										<input-template :input="page.forms.client.inputs.city" />
										<input-template :input="page.forms.client.inputs.postalCode" />
									</div>
									<div class="form-row">
										<input-template :input="page.forms.client.inputs.inputEmail" />
										<input-template :input="page.forms.client.inputs.phoneNumber" />
									</div>
									<input-template v-if="isAuthorizated" class="checkout-checkbox checkout-checkbox-optional" :input="page.forms.others.inputs.saveData" />
								</div>
							</div>
						</form>
						<divider-heading>{{langs.titles.invoice}}</divider-heading>
						<div class="form form-wrapper">
							<form class="form">
								<base-font class="invoice-text" variant="paragraph">{{langs.subtitles.invoice}}:</base-font>
								<div>
									<input-template class="checkout-checkbox checkout-checkbox-optional" :input="page.forms.invoice.inputs.otherDataToInvoice" />
								</div>
							</form>
							<div>
								<base-invoices/>
							</div>
						</div>
						<form class="form">
							<div>
								<divider-heading class="heading">{{langs.titles.payment}}</divider-heading>
								<div class="form-wrapper">
									<base-accordion class :accordion="page.forms.paymentMethod.accordion"/>
									<base-error :error="page.forms.paymentMethod.accordion.error"/>
								</div>
							</div>
						</form>
					</base-col>
					<base-col v-if="page.draftOrder" col="12" md="10" lg="5" xl="4" offsetMd="1" offsetLg="0" offsetXl="1">
						<div>
							<divider-heading class="heading">{{langs.titles.order}}</divider-heading>
							<cart-item class="cart-item" v-for="item in page.draftOrder.cart.items" :key="item.alias" :item="item"/>
						</div>
							<price-wrapper :cart="page.draftOrder.cart" extendedInfo :additionalCost="page.additionalCost" :toPay="page.toPay"/>
						<div>
							<divider-heading class="heading">{{langs.titles.additional_info}}</divider-heading>
							<ul class="checkout-list">
								<li v-for="(item, i) in additionalInfo" :key="i">
									<p v-html="item.info"></p>
								</li>
							</ul>
						</div>
						<div v-if="page.orderBumpProducts.length">
							<divider-heading class="heading">
								{{ orderBumpHeader }}
							</divider-heading>
							<order-bump :products="page.orderBumpProducts" />
						</div>
					</base-col>
					<base-col col="12">
						<form class="form">
							<div>
								<divider-heading class="heading">{{langs.titles.agreements}}</divider-heading>
								<div class="form-wrapper">
									<input-template class="checkout-checkbox" :input="page.forms.others.inputs.regulations" />
									<input-template class="checkout-checkbox" :input="page.forms.others.inputs.policy" />
									<input-template class="checkout-checkbox" :input="page.forms.others.inputs.marketing" />
								</div>
							</div>
						</form>
					</base-col>
				</base-row>
				<button-submit @click="page.submit()" :loading="page.processing" type="secondary" :rightSlot="true">
					<base-font color="white" tag="span">{{langs.buttons.next_step}}</base-font>
				</button-submit>
		</base-container>
	</section>
</template>
<script>
import ButtonSubmit from '~/website/front/components/molecules/ButtonSubmit'
import { InputTemplate } from '@f/core/forms/inputs'
import BaseInvoices from './components/BaseInvoices'
import CartItem from '~/shop/front/components/CartItem'
import DividerHeading from '~/website/front/components/molecules/DividerHeading'
import PriceWrapper from '~/shop/front/components/PriceWrapper'
import BaseAccordion from '@f/components/BaseAccordion'
import BaseError from '@f/components/BaseError'
import OrderBump from '~/shop/front/core/pages/Checkout/components/OrderBump'

export default {
	components: {
		BaseInvoices,
		ButtonSubmit,
		InputTemplate,
		CartItem,
		DividerHeading,
		PriceWrapper,
		BaseAccordion,
		BaseError,
		OrderBump
	},
	computed: {
		page () {
			return this.$app.page
		},
		additionalInfo () {
			return this.$app.page.value.additional_info
		},
		langs () {
			return this.$app.translator.get('checkout')
		},
		isAuthorizated () {
			return this.$app.authorization.isAuthorizated
		},
		orderBumpHeader () {
			return this.$app.settings.shop.orderBump.header || ''
		}
	},
	async mounted () {
		if (this.$app.authorization.isAuthorizated) {
			const service = await this.$app.getService('rext')
			await service.klaviyoCreateOrderPageEvent({ email: this.$app.authorization.client.user.email })
		}
	}
}
</script>
<style lang="scss" scoped>

.section {
	padding-top: 3rem;
	.heading {
		margin-block: 4rem;
	}
	.checkout-list {
		padding-left: 20px;
	}
	.button-submit {
		margin-top: 3rem;
		justify-content: center;
		padding-bottom: 0;

		@include media-breakpoint-up(md) {
			margin-top: 0;
			justify-content: flex-end;
		}
	}
}
.form {
	&-row {
		display: flex;
		flex-direction: column;
		@include media-breakpoint-up(md) {
			flex-direction: row;
		}
	}
	& :deep(label) {
		color: $primary;
	}
	&-wrapper {
		@include media-breakpoint-up(xl) {
			padding-left: 11rem;
		}
	}
	.checkout-checkbox {
		& :deep(label) {
			text-transform: uppercase;
		}
		&-optional {
			margin-bottom: 1.5rem;
			& :deep(.error-wrapper) {
				display: none;
			}
		}
	}
	& :deep(.invoice-text) {
		color: $gray-800;
		text-transform: uppercase;
		font-weight: bold;
		line-height: 1.9rem;
		margin-bottom: 1rem;
	}
}
.cart-item {
	pointer-events: none;

	:deep(.cart-item-main-data) {
		align-items: center;
		text-align: left;
	}
}
</style>
