<template>
	<div>
		<transition
			name="accordion"
			@enter="start"
			@after-enter="end"
			@before-leave="start"
			@after-leave="end"
		>
			<div key="invoices" class="top" v-if="invoiceOtherData">
				<form>
					<template>
						<div class="form-row">
							<input-template wider :input="inputs.companyName" />
							<input-template wider :input="inputs.companyNIP" />
						</div>
					</template>
					<input-template wider :input="inputs.invoiceAddress" />
					<div class="form-row">
						<input-template wider :input="inputs.invoicePostalCode" />
						<input-template wider :input="inputs.invoiceCity" />
					</div>
				</form>
			</div>
		</transition>
	</div>
</template>
<script>
import { InputTemplate } from '@f/core/forms/inputs'

export default {
	components: {
		InputTemplate
	},
	methods: {
		start (el) {
			el.style.height = el.scrollHeight + 'px'
		},
		end (el) {
			el.style.height = ''
		}
	},
	computed: {
		page () {
			return this.$app.page
		},
		inputs () {
			return this.page.forms.invoice.inputs
		},
		invoiceOtherData () {
			return this.inputs.otherDataToInvoice.value
		}
	}
}
</script>
<style lang="scss" scoped>

.accordion-enter-active,
.accordion-leave-active {
	will-change: height, opacity;
	transition: height 0.3s ease, opacity 0.3s ease;
	overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
	height: 0 !important;
	opacity: 0;
}
.invoice-checkoboxes {
	margin-bottom: 2rem;
}
.form-row {
	display: flex;
	flex-direction: column;
	@include media-breakpoint-up(md) {
		flex-direction: row;
	}
}
</style>
