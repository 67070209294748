import { Form } from '@f/core/forms/Form'
import { InputText, InputEmail } from '@f/core/forms/inputs'
import { email, min, required, postalCode, phoneNumber } from 'utils/inputs/validation'

class ClientForm extends Form {
	constructor (app) {
		super(...arguments)
		this.app = app
	}

	async create (draftOrder = {}) {
		this.addInput(InputText.create({
			translations: () => this.app.translator.get('forms.checkout.firstName'),
			attribute: 'firstName',
			name: 'firstName',
			value: draftOrder.client?.firstName || '',
			validation: [required(), min(3, 'Wprowadź prawidłowe imię.')]
		}))
		this.addInput(InputText.create({
			translations: () => this.app.translator.get('forms.checkout.lastName'),
			attribute: 'lastName',
			name: 'lastName',
			value: draftOrder.client?.lastName || '',
			validation: [required(), min(2, 'Wprowadź prawidłowe nazwisko')]
		}))
		this.addInput(InputText.create({
			translations: () => this.app.translator.get('forms.checkout.streetAndBuildingNumber'),
			attribute: 'streetAndBuildingNumber',
			name: 'streetAndBuildingNumber',
			value: draftOrder.client?.streetAndBuildingNumber || '',
			validation: [required()]
		}))
		this.addInput(InputText.create({
			translations: () => this.app.translator.get('forms.checkout.postalCode'),
			attribute: 'postalCode',
			name: 'postalCode',
			mask: '##-###',
			inputmode: 'numeric',
			value: draftOrder.client?.postalCode || '',
			validation: [required(), postalCode()]
		}))
		this.addInput(InputText.create({
			translations: () => this.app.translator.get('forms.checkout.city'),
			attribute: 'city',
			name: 'city',
			value: draftOrder.client?.city || '',
			validation: [required()]
		}))
		this.addInput(InputEmail.create({
			translations: () => this.app.translator.get('forms.checkout.inputEmail'),
			attribute: 'email',
			name: 'inputEmail',
			value: draftOrder.client?.email || '',
			validation: [required(), email()]
		}))
		this.addInput(InputText.create({
			translations: () => this.app.translator.get('forms.checkout.phoneNumber'),
			attribute: 'phoneNumber',
			name: 'phoneNumber',
			mask: '+48 ### ### ###',
			inputmode: 'numeric',
			value: draftOrder.client?.phoneNumber,
			validation: [required(), phoneNumber(15)]
		}))
	}
}

export { ClientForm }
