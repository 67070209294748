import { Emitter } from '@f/core/Emitter'
import { CheckoutDeliveryAccordion, CheckoutDeliveryAccordionItem } from '~/shop/front/components/CheckoutAccordion/CheckoutDeliveryAccordion'

class DeliveryForm extends Emitter {
	accordion

	constructor (app) {
		super()
		this.app = app
	}

	async create (draftOrder) {
		this.draftOrder = draftOrder

		const deliveries = await this.draftOrder.getDeliveries()
		this.deliveries = deliveries

		this.accordion = new CheckoutDeliveryAccordion()
		deliveries.map(delivery => {
			const item = new CheckoutDeliveryAccordionItem(delivery)
			this.accordion.addItem(item)
			if (draftOrder?.delivery?.alias === delivery.alias) item.setInitialActive(draftOrder.delivery)
		})

		this.accordion.initReactive()

		this.accordion.on('active:changed', () => this._emit('value:changed', this.accordion.getValue()))
	}

	validate () {
		return this.accordion.validate()
	}

	getValues () {
		return this.accordion.getValue()
	}

	get cost () {
		return this.accordion.cost
	}

	getPayments () {
		const { alias } = this.accordion.getValue() || {}

		const delivery = this.deliveries.find(delivery => delivery.alias === alias)

		if (!delivery) return {}

		return delivery.payments
	}
}

export { DeliveryForm }
